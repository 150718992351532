<template>
  <main class="media-open-page page">
    <BreadcrumbsComponent
      v-if="media && media.title"
      :title="media.title"
      :links="[{ title: 'СМИ о нас', to: { name: 'media' } }]"
    />
    <div class="container-padding">
      <div v-if="media" class="media-open-page__inner">
        <ImgComponent v-if="media.img" :img="media.img" class="media-open-page__image" />
        <div class="media-open-page__body">
          <h2 v-if="media.title" class="media-open-page__title">{{ media.title }}</h2>
          <span v-if="media.description">{{ media.description }}</span>
        </div>
        <div class="media-open-page__footer">
          <span v-if="media.source">
            <b>Источник: </b>
            <a :href="media.link" target="_blank" class="media-open-page__link">{{ media.source }}</a>
          </span>
          <span><b>Дата публикации: </b>{{ media.data | humanDate }}</span>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
import MEDIA_OPEN_PAGE from "gql/pages/MediaOpenPage.graphql";
export default {
  name: "MediaOpenPage",
  metaInfo() {
    const meta = this.$store.state.media.media_item;
    return {
      title: meta?.title,
    };
  },
  async asyncData({ res, apollo, store, route }) {
    if (!route.params.id && res) {
      res.status(404);
    }
    await apollo.defaultClient
      .query({
        query: MEDIA_OPEN_PAGE,
        variables: {
          id: parseInt(route.params.id),
        },
      })
      .then(({ data }) => {
        if (data) {
          store.dispatch("media/save", data);
        } else {
          if (res) {
            res.status(404);
          }
        }
      })
      .catch(() => {});
  },
  computed: {
    media() {
      return this.$store.state.media.media_item;
    },
  },
  components: {
    ImgComponent,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
.media-open-page {

  &__inner {
    padding 50px 0 0 0
    display grid
    grid-gap 30px
  }

  &__image {
    max-height 550px
    border-radius 10px
  }

  &__body {
    display grid
    grid-gap 15px
  }

  &__title {
    margin-bottom 0
  }

  &__footer {
    display flex
    flex-direction column
    //align-items end
    gap 5px
  }

  &__link {
    &:hover {
      text-decoration underline
    }
  }
}
</style>
